import FileDropzone from "./FileDropzone";
import CampaignBlastForm from "./Form/CampaignBlastForm";
import StyledTable from "./StyledTable";
import { useEffect, useMemo, useState } from "react";
import Papa from "papaparse";
import MessagePreview from "./MessagePreview";
import { isValidPhoneNumber } from "libphonenumber-js";
import { toast } from "react-toastify";

import SwipeableTabs from "./SwipableTabs";
import useIsMobile from "../hooks/useIsMobile";
import AudienceTab from "./AudienceTab";
import InteractionsTab from "./InteractionsTab";

const CampaignComponent = (props) => {
  const isMobile = useIsMobile();

  const { campaign, setCampaign, control } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const [jsonFile, setJsonFile] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);

  const columns = useMemo(() => {
    if (jsonFile) {
      const { fields } = jsonFile?.meta;

      console.log("the fields: ", fields);
      return fields?.length > 0
        ? fields?.map((fieldName) => ({
            Header: fieldName,
            accessor: fieldName,
          }))
        : [];
    }
  }, [jsonFile]);

  useEffect(() => {
    if (campaign?.base64FileContent) {
      console.log("inside get campaign use effect 2: ", campaign);

      const decodedFileContent = atob(campaign?.base64FileContent);

      // Use Blob to create a file-like object
      const csvBlob = new Blob([decodedFileContent], { type: "text/csv" });
      const csvFile = new File([csvBlob], campaign?.fileName);

      Papa.parse(csvFile, {
        complete: (result) => {
          console.log("Parsed Result:", result);
          setJsonFile(result);
        },
        header: true,
      });
    }
  }, [campaign?.base64FileContent]);

  useEffect(() => {
    if (jsonFile) {
      const validationErrors = validateJsonFile(jsonFile);

      console.log("validation errors: ", validationErrors);

      if (validationErrors.length > 0) {
        // Create a formatted string from the errors
        const formattedErrors = validationErrors.join("\n");

        setFileErrors(formattedErrors);

        // Display the toast
        toast.error(
          <div>
            <strong>The file was invalid</strong>
            <div>{formattedErrors}</div>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } else {
        setFileErrors(null);
      }
    }
  }, [jsonFile]);

  const validateJsonFile = (jsonFile) => {
    const errors = [];
    const { data, meta } = jsonFile;

    // Normalize fields to lowercase for case-insensitive comparison
    const normalizedFields = meta.fields.map((field) =>
      field.trim().toLowerCase()
    );

    // Define valid fields
    const validFields = ["name", "phone number", "phonenumber"];

    // Check if the required fields are present
    if (
      !normalizedFields.includes("name") ||
      !validFields.some((field) => normalizedFields.includes(field))
    ) {
      errors.push(
        `The file must contain the fields 'Name' and 'Phone Number'. Provided fields: [${meta.fields.join(
          ", "
        )}].`
      );
    }

    // Validate data rows
    data.forEach((row, index) => {
      const nameField = row.name?.trim();
      const numberField =
        row["phone number"]?.trim() || row.phonenumber?.trim();

      console.log("name field: ", nameField);
      console.log("number field: ", numberField);

      const rowErrors = [];

      if (nameField && nameField.length > 120) {
        rowErrors.push(`The name in row ${index + 1} exceeds 120 characters.`);
      }

      if (numberField && !isValidPhoneNumber(numberField, "US")) {
        rowErrors.push(
          `The phone number in row ${index + 1} is not a valid US phone number.`
        );
      }

      if (rowErrors.length > 0) {
        errors.push(...rowErrors);
      }
    });

    return errors;
  };

  const handleFileUpload = (files) => {
    console.log("Received files:", files);

    const rawFile = files[0];

    const { name, size } = rawFile;

    const reader = new FileReader();
    reader.readAsDataURL(rawFile);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        base64FileContent: base64String,
        audienceFileName: name,
        audienceFileSize: size,
      }));
    };
    reader.onerror = (error) => {
      console.log("Error reading file:", error);
    };
  };

  const campaignDetailsTab = (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: isMobile ? "100%" : "60%",
        }}
      >
        <h2>Campaign Details</h2>
        <CampaignBlastForm
          campaign={campaign}
          setCampaign={setCampaign}
          control={control}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: isMobile ? "100%" : "40%",
        }}
      >
        <MessagePreview
          imageFile={campaign?.messageImage}
          message={campaign?.messageBody}
        />
      </div>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: isMobile ? "auto" : "100%",
        padding: isMobile ? "0em" : "2em",
        boxSizing: "border-box",
      }}
    >
      <SwipeableTabs
        tabs={[
          { label: "Campaign Details", content: campaignDetailsTab },
          {
            label: "Audience",
            // content: audienceTab,
            content: (
              <AudienceTab
                isMobile={false}
                handleFileUpload={handleFileUpload}
                campaign={campaign}
                setCampaign={setCampaign}
              />
            ),
          },
          // { label: "Interactions", content: interactionsTab },
          { label: "Interactions", content: <InteractionsTab /> },
        ]}
      />
    </div>
  );
};

export default CampaignComponent;
