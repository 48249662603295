import { AppBar, Button, Paper, Toolbar } from "@mui/material";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";
import CampaignComponent from "../components/CampaignComponent";
import { useDispatch, useSelector } from "react-redux";

import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";

import {
  createCampaign,
  deleteCampaign,
  getCampaign,
  resetCampaign,
  sendCampaign,
  updateCampaign,
} from "../models/campaign/campaignSlice";
import useIsMobile from "../hooks/useIsMobile";

const defaultCampaign = {
  fromNumber: null,
  messagingServiceSID: null,
  messageBody: null,
  campaignName: null,
  promotion: null,
};

const Campaign = (props) => {
  const isMobile = useIsMobile();
  const role = sessionStorage.getItem("role");
  const { businessId, campaignId } = useParams();

  const [campaignDetails, setCampaignDetails] = useState(defaultCampaign);

  const campaignSlice = useSelector((state) => state.campaign);

  const { campaign } = campaignSlice;

  const { control, handleSubmit, formState, trigger, reset } = useForm();

  const navigate = useNavigate();

  const navigateBack = () => navigate(-1);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      console.log("resetting campaign");
      dispatch(resetCampaign());
    };
  }, []);

  useEffect(() => {
    if (businessId && campaignId) {
      console.log("fetching campaign");
      dispatch(getCampaign({ businessId: businessId, campaignId: campaignId }));
    }
  }, [businessId, campaignId]);

  useEffect(() => {
    if (campaignDetails) {
      reset(campaignDetails);
    }
  }, [campaignDetails, reset]);

  useEffect(() => {
    setCampaignDetails({ ...campaign });
  }, [campaign]);

  useEffect(() => {
    console.log("Form errors:", formState.errors);
    console.log("campaignDetails: ", campaignDetails);
  }, [formState.errors]);

  const handleSendCampaign = () => {
    console.log("handle send campaign");

    dispatch(
      sendCampaign({
        businessId: businessId,
        campaignId: campaignId ? campaignId : campaign?.campaignId,
      })
    )
      .then(unwrapResult)
      .then(() => {
        toast.success("Campaign sent successfully");
        // setTimeout(navigateBack, 550);
      })
      .catch(() => toast.error("Failed to send the campaign"));
  };

  const handleEditCampaign = () => {
    const promotionPayload =
      campaignDetails.promotion && campaignDetails.promotion.discount != null
        ? {
            ...campaignDetails.promotion,
            discount: parseFloat(campaignDetails.promotion.discount),
          }
        : null;

    const campaignPayload = {
      campaignId: campaignId,
      businessId: businessId,
      campaign: {
        ...campaignDetails,
        ...(promotionPayload && { promotion: promotionPayload }),
      },
    };

    dispatch(updateCampaign(campaignPayload))
      .then(unwrapResult)
      .then(() => {
        toast.success("Campaign successfully updated");
      })
      .catch(() => {
        toast.error("Failed to update campaign");
      });
  };

  const handleDeleteCampaign = () => {
    dispatch(deleteCampaign({ campaignId, businessId }))
      .then(unwrapResult)
      .then(() => {
        toast.success("Campaign successfully deleted");
        setTimeout(navigateBack, 550);
      })
      .catch(() => toast.error("Failed to delete campaign"));
  };

  const handleCreateCampaign = () => {
    const promotionPayload =
      campaignDetails?.promotion?.discount != null
        ? {
            discount: parseFloat(campaign.promotion.discount),
          }
        : null;

    const campaignPayload = {
      businessId: businessId,
      campaign: {
        ...campaignDetails,
        ...(promotionPayload && { promotion: promotionPayload }),
      },
    };

    dispatch(createCampaign(campaignPayload))
      .then(unwrapResult)
      .then(() => {
        toast.success("Campaign successfully created");
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to create campaign");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: isMobile ? "1em" : "0em",
      }}
    >
      <h1>{`Campaign: ${
        campaign?.campaignName ? campaign?.campaignName : "N/A"
      }`}</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1em",
          marginBottom: "2em",
        }}
      >
        <Button
          onClick={handleSubmit(handleCreateCampaign)}
          variant="outlined"
          disabled={(campaignId && businessId) || campaign?.campaignId}
        >
          Create
        </Button>
        <Button
          disabled={role != "admin" || (!campaignId && !campaign?.campaignId)}
          onClick={() => handleDeleteCampaign()}
          variant="outlined"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
        <Button
          disabled={role != "admin" || (!campaignId && !campaign?.campaignId)}
          onClick={handleSubmit(handleEditCampaign)}
          variant="outlined"
          startIcon={<EditIcon />}
        >
          Update
        </Button>
        <Button
          disabled={role != "admin" || (!campaignId && !campaign?.campaignId)}
          onClick={handleSubmit(handleSendCampaign)}
          variant="outlined"
          startIcon={<SendIcon />}
        >
          Send
        </Button>
      </div>

      <CampaignComponent
        campaign={campaignDetails}
        setCampaign={setCampaignDetails}
        control={control}
      />
    </div>
  );
};

export default Campaign;
